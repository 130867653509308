import React, { Fragment } from 'react';
import {REACT_APP_BASE_URL as URL} from '../../config/env';
import {title} from '../../config/env';
import './styles.css';

const IframeApp = () => {
  return (
    <Fragment>
      <iframe src={URL} title={title}></iframe>
    </Fragment>
  );
}

export default IframeApp;