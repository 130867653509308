import React, { useState, useEffect } from 'react';
import './styles.css';

const DesktopView = () => {
  const [qrCode, setQrCode] = useState('');
  useEffect(() => {
    setQrCode(`https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${window.location.href}`);
  }, []);
  return (
      <div class="container">
          <div class="header">This Application is not available on desktop.</div>
          <div class="header">Please scan the following QR code to open it in mobile</div>
          <img alt='qrcode' src={qrCode}/>
      </div>
  );
};

export default DesktopView;
