import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Homepage from './Homepage';
import { title } from '../config/env';

const App = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Homepage />
    </Fragment>
  );
}

export default App;
