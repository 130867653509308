import React from 'react';
import { Breakpoint, BreakpointProvider } from 'react-socks';
import IframeApp from '../IframeApp';
import DesktopView from '../DesktopView/index';

const Homepage = () => (
<BreakpointProvider>
  <Breakpoint medium up>
    <DesktopView />
  </Breakpoint>

  <Breakpoint small down>
    <IframeApp />
  </Breakpoint>
</BreakpointProvider>);

export default Homepage;
